export const CREATE_NOTE = 'CREATE_NOTE';
export const FETCH_NOTE = 'FETCH_NOTE';
export const FETCH_NOTES = 'FETCH_NOTES';
export const EDIT_NOTE = 'EDIT_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CLEAR_SELECTED_NOTE = 'CLEAR_SELECTED_NOTE';
export const CLEAR_NOTES = 'CLEAR_NOTES';
export const DELETE_NOTES = 'DELETE_NOTES';
